.hero {
  padding-bottom: 0;
  position: relative;
}

.hero-body {
  padding-bottom: 0;
  overflow: hidden;

  .column {
    padding-bottom: 0;
  }

  .hero-image-profile {
    transform: translateY(-25px);

    @media screen and (max-width: $mobile) {
      display: none;
    }
  }

  .hero-typo {
    padding-top: 15%;

    @media screen and (max-width: $tablet) {
      padding-top: 5rem;
      padding-bottom: 10rem;
    }

    .hero-button {
      background-color: transparent;
      color: #fff;
      margin-right: 1rem;
      transition: background-color $transition-speed $transition-ease,
        color $transition-speed $transition-ease;

      &:hover {
        background-color: #eeeeee;
        color: #474747;
      }
    }

    #hero-cta {
      background-color: $accent;
      border-color: $accent;
      transition: background-color $transition-speed $transition-ease;

      &:hover {
        color: #fff;
        background-color: $accent-darker;
      }
    }

    .hero-heading-wrapper {
      p {
        opacity: 0.8;
      }

      .hero-heading {
        line-height: 1;
        font-size: 4.5rem;
        @media screen and (max-width: $mobile) {
          font-size: 4rem;
        }
      }
    }
  }
}

.spacer {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.spacer-top {
  aspect-ratio: 960/200;
  background-image: url("../images/spacer-top.svg");
  position: absolute;
  bottom: 0;
}

.spacer-section-2 {
  aspect-ratio: 960/200;
  background-image: url("../images/spacer-2.svg");
}

.spacer-section-3 {
  aspect-ratio: 960/200;
  background-image: url("../images/spacer-2.svg");
  transform: rotate(180deg);
}
