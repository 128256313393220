.footer {
  background-color: $body-dark;

  .footer-top {
    margin-bottom: 0.5rem;
    align-items: center;
  }

  .socials {
    margin-top: 0.5rem;
    display: flex;
    .icon {
      width: 2rem;
      height: 2rem;
      background-color: $text-white;
      color: $body-dark;
      border-radius: 50%;
      margin-right: 1rem;
      transition: background-color $transition-speed $transition-ease,
        transform $transition-speed $transition-ease,
        color $transition-speed $transition-ease;

      &:hover {
        background-color: $accent;
        transform: scale(1.1);
        color: #fff;
      }
    }
  }

  img {
    height: 1rem;
    margin-right: 0.5rem;
  }
}
