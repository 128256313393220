.about {
  background-color: #141414;
  padding: 2rem;

  @media screen and (max-width: $mobile) {
    padding: 2rem 2rem !important;
  }

  @media screen and (max-width: $tablet) {
    padding: 2rem 8rem;
  }

  .box {
    color: $text-white;
    background-color: $body-color;
    height: 100%;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }

    hr {
      background-color: $body-dark;
      height: 1px;
    }
  }

  .top-content {
    img {
      width: 30%;

      @media screen and (max-width: $mobile) {
        width: 20%;
      }
    }
  }
}

.about-profile-image {
  max-height: 35rem;
  width: auto;
}
