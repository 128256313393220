.contact-wrapper {
  @media screen and (max-width: $mobile) {
    padding: 0 2rem;
  }

  @media screen and (max-width: $tablet) {
    padding: 0 2rem;
  }
}

.contact-entry-wrapper {
  width: 50%;
  padding: 0.25rem 1rem 0.25rem 0;

  @media screen and (max-width: $tablet) {
    width: 50%;
  }

  @media screen and (max-width: $mobile) {
    width: 100%;
  }

  .contact-entry {
    background-color: $text-white;
    color: $body-dark;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    transition: transform $transition-speed $transition-ease;

    .icon {
      width: 2rem;
      height: 2rem;
      background-color: $body-dark;
      color: $text-white;
      border-radius: 50%;
      margin-right: 1rem;
      transition: background-color $transition-speed $transition-ease;
    }

    &:hover {
      transform: scale(1.1);

      .icon {
        background-color: $accent;
      }
    }
  }
}
