.projects {
  background-color: $body-dark;
}

.projects-content {
  @media screen and (max-width: $mobile) {
    padding: 2rem;
  }

  @media screen and (max-width: $tablet) {
    padding: 2rem;
  }

  .project-tile {
    aspect-ratio: 1 / 1;
    background-color: $body-dark;
    color: #232323;
    overflow: hidden;
    position: relative;
    transition: transform $transition-speed $transition-ease;
    width: 25%;

    @media screen and (max-width: $tablet) {
      width: 50%;
    }

    @media screen and (max-width: $mobile) {
      width: 50%;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }

    //   Header
    img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .content-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: rgba($color: #000000, $alpha: 0.6);
      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        color: $text-white;
        padding: 1rem;
      }
    }

    &:hover {
      transform: scale(1.1);
      z-index: 20;
      cursor: pointer;
    }
  }
}

.reference-card {
  background-color: $body-color;
  color: $text-white;
}
