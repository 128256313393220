.navbar {
  background-color: transparent;
  padding: 1rem 0 1rem 0;

  .navbar-logo {
    max-height: 2rem;
  }

  .navbar-brand {
    margin: 0 1rem 0 1rem;
  }

  .navbar-menu {
    padding-left: 1rem;
    border-left: solid 1px #fff;
    background-color: transparent;
  }

  .navbar-item {
    color: $text-white;
    text-transform: uppercase;

    &:hover {
      background-color: transparent;
    }
  }

  .nav-button {
    background-color: transparent;
    border: solid 1px #fff;
    color: $text-white;
    transition: background-color $transition-speed $transition-ease,
      color $transition-speed $transition-ease;

    &:hover {
      background-color: #eeeeee;
      color: #474747;
    }
  }
}
@media screen and (max-width: 1024px) {
  .navbar-menu {
    display: block;
    opacity: 0;

    position: absolute; /* or float: left; width: 100%;*/
    left: 0;
    right: 0;

    transform: translateY(-50%);
    transition: all 0.4s ease-in-out;
    pointer-events: none;
  }

  .navbar-menu.is-active {
    background-color: $body-color;
    opacity: 1;
    transform: none;
    pointer-events: auto;
  }
}
