@charset "utf-8";

// Colors
$body-color: #1e252b;
$body-dark: #141414;
$text-white: #ffffff;
$accent: #3c58a4;
$accent-darker: #284183;

// Transition
$transition-speed: 0.25s;
$transition-ease: ease;

// Breakpoints
$mobile: 756px;
$tablet: 940px;

@import "~bulma/bulma";
@import "components/navbar";
@import "components/buttons";
@import "components/hero";
@import "components/about";
@import "components/projects";
@import "components/contact";
@import "components/footer";
@import "components/resume";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  overflow: auto;
  background-color: $body-color;
  color: $text-white;
  font-family: "Montserrat", sans-serif;
}

#portfolio {
  min-height: 75vh;
}
